import React, { Component } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '@components/layout/Layout'
import ReferenceComponent from '@components/references/reference.component'
import ReadNext from '@components/read-next-button/read-next-button'
import { references } from '@components/references/comorbidities-references'
import { liveRampEventTracking } from '../../../app-utils'
import LearnMoreComponent from '@components/learn-more/learn-more.component'

import './comorbidities.scss'

class Comorbidities extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    liveRampEventTracking('comorbidity-page-view')
  }

  render() {
    const readNext = {
      readNextContent: 'Modifiable risks for CV disease',
      goTo: '/comorbidities-risk/risk/',
      tracking: 'comorbidities,	click, modifiable risks cv disease - comorbidities',
    }

    const learnMoreContent = {
      className: 'learn-more-wrapper-gray',
      title: 'Some risk factors for CV disease are modifiable',
      supText: '3-12',
      linkLabel: 'Managing the risks',
      link: '/comorbidities-risk/risk/',
      tracking: 'comorbidities , click, modifiable risks cv disease - comorbidities'
    }

    return (
      <Layout addedClass='page-comorbidities' location={this.props.location}>
        <div className="comorbidities-page-header " role="img" alt="Cloudy open terrain path with boulders">
          <div className="primary-container">
            <div className="comorbidities-header-text">
              <h1>Cardiovascular and Cardiometabolic Comorbidities Are Frequently Observed in Patients With Narcolepsy</h1>
              <h3>Research has demonstrated an increased prevalence of certain comorbid conditions, including cardiovascular (CV) and cardiometabolic conditions, in&nbsp;patients with narcolepsy compared with matched controls.<sup>1,2</sup></h3>
            </div>
          </div>
        </div>
        <div className="background-white">
          <section className="section-gradient" />
        </div>
        <div className="primary-container">
          <div className="info-section-title">
            <h2>CV Comorbidities</h2>
          </div>
          <div className="graph-info-section">
            <div className="info-section">
              <h3 className="info-section-header">
                An increased prevalence of cardiovascular and cardiometabolic conditions, such as hypertension, obesity, diabetes, and hypercholesterolemia has been reported in people with narcolepsy compared with matched controls<sup>1,2</sup>
              </h3>
              <ul className="info-section-content">
                <li>In one study, the odds of heart disease in patients with narcolepsy were twice those of control subjects. Hypertension and hypercholesterolemia were also more frequently observed in the narcolepsy group than in the control group.<sup>2</sup></li>
                <li>Additionally, a separate study showed that the prevalence of obesity, diabetes, and stroke was significantly increased in patients with narcolepsy compared with controls.<sup>1</sup></li>
                <li>The risk and presence of comorbid conditions may further complicate both diagnosis and disease management.<sup>1,13,14</sup></li>
              </ul>
              <h3 className="info-section-header">
                The pathophysiology of narcolepsy, which involves hypocretin deficiency,<sup>15</sup> may partially explain the increased incidences of cardiovascular comorbidities seen in patients with narcolepsy
                <sup>10-12</sup>
              </h3>
              <ul className="info-section-content">
                <li>Preclinical data suggest that sleep and proper release of hypocretin protect against the development of atherosclerosis<sup>10</sup>—a disease of the arteries characterized by the deposition of plaques of fatty material on their inner walls.<sup>16</sup></li>
                <li>Clinical research also suggests that the nondipping nocturnal blood pressure (BP) profile frequently observed in patients with narcolepsy may be partly due to hypocretin deficiency and disrupted nighttime sleep.<sup>11,12</sup></li>
                <li>In the general population, atherosclerosis and blunted nocturnal BP dipping are independent risk factors for CV disease, CV events, and mortality.<sup>16-21</sup></li>
              </ul>
            </div>
            <div className="graph-section">
              <div className="content-wrapper-header">
                <LearnMoreComponent learnMoreContent={learnMoreContent} />
              </div>
              <h2>CV Comorbidities</h2>
              <div className="graph-section-header">
                    Increased Risk of Comorbid Conditions
                <br className="visible-xl" /> in Patients With Narcolepsy*
              </div>
              <div className="image-wrapper">
                <StaticImage src={'../../../assets/images/infographics/infographic-increased-comorbid-risk.png'} width={518} alt="6 hexagons form a circle listing rates of comorbid conditions" />
                <p className="minor-comment">*Odds ratios in patients with narcolepsy vs controls without narcolepsy.</p>
                <p className="minor-comment">†Odds ratio is from a retrospective analysis of US medical claims data for 93,122 people with narcolepsy and a control group of 46,559 people without narcolepsy.<sup>1</sup></p>
                <p className="minor-comment">‡Odds ratio is from an interview study of 320 patients with narcolepsy compared with a matched general population sample of 1464 participants.<sup>2</sup></p>
              </div>
            </div>
          </div>
        </div>
        <div className="primary-container">
          <ReadNext readNext={readNext} />
        </div>
        <ReferenceComponent referenceList={references} />
      </Layout>
    )
  }
}

export default Comorbidities
